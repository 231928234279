@tailwind base;
@tailwind components;
@tailwind utilities;


@import "../src/assets/css/plyr.css";
@import "../src/assets/css/fonts.css";
@import "../src/assets/css/progress.css";

@layer base {
  html {
    font-family: "CooperHewitt", "ProximaNova", "Open Sans", system-ui,
      sans-serif;
  }
}
