.progress {
  height: 4px;
  background-color: rgba(75, 75, 75, 0.2);
  background-image: none;
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 20px;
}

.progress.transparent {
  background-color: rgba(0, 0, 0, 0.28);
}

.progress.progress-small {
  height: 3px;
}

/* Circular Progress : indeterminate color
------------------------------------
*/
.progress-bar-indeterminate {
  background: url("../img/progress/progress-bar-master.svg") no-repeat top
    left;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.progress-bar-indeterminate.progress-bar-primary {
  background-image: url("../img/progress/progress-bar-primary.svg");
  background-color: transparent;
}

.progress-bar-indeterminate.progress-bar-complete {
  background-image: url("../img/progress/progress-bar-complete.svg");
  background-color: transparent;
}

.progress-bar-indeterminate.progress-bar-success {
  background-image: url("../img/progress/progress-bar-success.svg");
  background-color: transparent;
}

.progress-bar-indeterminate.progress-bar-info {
  background-image: url("../img/progress/progress-bar-info.svg");
  background-color: transparent;
}

.progress-bar-indeterminate.progress-bar-warning {
  background-image: url("../img/progress/progress-bar-warning.svg");
  background-color: transparent;
}

.progress-bar-indeterminate.progress-bar-danger {
  background-image: url("../img/progress/progress-bar-danger.svg");
  background-color: transparent;
}

.progress-bar-indeterminate.progress-bar-master {
  background-image: url("../img/progress/progress-bar-master.svg");
  background-color: transparent;
}

/* Progress Bar
------------------------------------
*/
.progress-bar {
  box-shadow: none;
  border-radius: 0;
  background-color: #4b4b4b;
  background-image: none;
  -webkit-transition: all 1000ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 1000ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

/* Progress Bar : Color Options
------------------------------------
*/
.progress-bar-primary {
  background-color: #7252d3;
  background-image: none;
}

.progress-bar-complete {
  background-color: #0072ec;
  background-image: none;
}

.progress-bar-success {
  background-color: #19ad79;
  background-image: none;
}

.progress-bar-info {
  background-color: #3b4752;
  background-image: none;
}

.progress-bar-warning {
  background-color: #ffd945;
  background-image: none;
}

.progress-bar-danger {
  background-color: #d83c31;
  background-image: none;
}

.progress-bar-white {
  background-color: #fff;
  background-image: none;
}

.progress-bar-black {
  background-color: #212121;
  background-image: none;
}

.progress-info .bar,
.progress .bar-info {
  background-color: #1b2839;
  background-image: none;
}

.progress-warning .bar,
.progress .bar-warning {
  background-color: #aa7918;
  background-image: none;
}

.progress-danger .bar,
.progress .bar-danger {
  background-color: #900f17;
  background-image: none;
}

.progress-white .bar,
.progress .bar-white {
  background-color: #fff;
  background-image: none;
}

.progress-success.progress-striped .bar,
.progress-striped .bar-success {
  background-color: #19ad79;
}

.progress-info.progress-striped .bar,
.progress-striped .bar-info {
  background-color: #3b4752;
}

/* Circular Progress : indeterminate 
------------------------------------
*/
.progress-circle-indeterminate {
  background: url("../img/progress/progress-circle-master.svg") no-repeat top
    left;
  width: 50px;
  height: 50px;
  background-size: 100% auto;
  margin: 0 auto;
}

.progress-circle-indeterminate.progress-circle-warning {
  background-image: url("../img/progress/progress-circle-warning.svg");
}

.progress-circle-indeterminate.progress-circle-danger {
  background-image: url("../img/progress/progress-circle-danger.svg");
}

.progress-circle-indeterminate.progress-circle-info {
  background-image: url("../img/progress/progress-circle-info.svg");
}

.progress-circle-indeterminate.progress-circle-primary {
  background-image: url("../img/progress/progress-circle-primary.svg");
}

.progress-circle-indeterminate.progress-circle-success {
  background-image: url("../img/progress/progress-circle-success.svg");
}

.progress-circle-indeterminate.progress-circle-complete {
  background-image: url("../img/progress/progress-circle-complete.svg");
}

/* Circular Progress 
------------------------------------
*/
.progress-circle {
  display: block;
  height: 45px;
  margin: 0 auto;
  position: relative;
  width: 45px;
  -webkit-backface-visibility: hidden;
}

.progress-circle.progress-circle-warning .pie .half-circle {
  border-color: #ffd945;
}

.progress-circle.progress-circle-danger .pie .half-circle {
  border-color: #d83c31;
}

.progress-circle.progress-circle-info .pie .half-circle {
  border-color: #3b4752;
}

.progress-circle.progress-circle-primary .pie .half-circle {
  border-color: #7252d3;
}

.progress-circle.progress-circle-success .pie .half-circle {
  border-color: #19ad79;
}

.progress-circle.progress-circle-complete .pie .half-circle {
  border-color: #0072ec;
}

.progress-circle.progress-circle-thick .pie .half-circle,
.progress-circle.progress-circle-thick .shadow {
  border-width: 5px;
}

.progress-circle .pie {
  clip: rect(0, 45px, 45px, 22.5px);
  height: 45px;
  position: absolute;
  width: 45px;
}

.progress-circle .pie .half-circle {
  border: 3px solid #4b4b4b;
  border-radius: 50%;
  clip: rect(0, 22.5px, 45px, 0);
  height: 45px;
  position: absolute;
  width: 45px;
}

.progress-circle .shadow {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  height: 100%;
  width: 100%;
  box-shadow: none !important;
}
